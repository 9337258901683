import React, { useState, useEffect } from "react";
import { Button, Form, Input, Row, Col } from "antd";
import { PhoneOutlined } from "@ant-design/icons";
import Layout from "../../components/Layout";
import client from "../../helpers/apiConfig";
import contactImage from "../../img/insuremartusa-contact.png";
import * as analytics from "../../helpers/analytics";
import fbTrack from "../../helpers/pixel";

import "./styles/contact-page.scss";

const SERVICE_LINE = process.env.INSURITA_SERVICE_LINE;

const ContactPage = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [user, setUser] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }, []);

  const sendInquiry = () => {
    form
      .validateFields()
      .then(async (values) => {
        setLoading(true);
        setUser(values);
        try {
          const response = await client.post("/post_lead?vendor_id=99", values, {
            headers: {
              "Content-Type": "application/json"
            }
          });
          if (response) {
            setLoading(false);
            setSuccess(true);
            fbTrack("track", "Contact Form", { ...values });
            analytics.submitRequestForm("success");
          }
        } catch (error) {
          setLoading(false);
          form.setFields([
            {
              name: "phone_number",
              errors: [error.response.data]
            }
          ]);
          analytics.submitRequestForm("failed");
        }
      })
      .catch((info) => {
        setLoading(false);
        console.log("Validate Failed:", info);
      });
  };

  return (
    <Layout>
      <div className="contact-page">
        <section className="contact-page__hero">
          <div className="wrapper-md">
            <div className="contact-page__content">
              <div>
                <h2 className="mb-3 is-size-3-mobile is-size-2-tablet has-text-weight-bold contact-page__hero__card__title line-height-2">
                  Contact Us
                </h2>
                <p className="line-height-3 mb-5 has-text-weight-light">
                  You can use the form below or call to be instantly connected with a U.S. based
                  licensed healthcare representative{" "}
                  <a className="contact-page__content__number" href={`tel:${SERVICE_LINE}`}>
                    {SERVICE_LINE}
                  </a>
                </p>
              </div>
            </div>

            {success ? (
              <div className="contact-page__form has-text-centered">
                <p className="pb-5">
                  Hi {user.first_name}! We'll get back to you as soon as we can but if you would
                  like to call us now and talk about your inquiry, click the button below!
                </p>
                <Button
                  className="button is-primary is-rounded landing__hero__card__button"
                  type="primary"
                  size="large"
                  onClick={() => window.open("tel:(833)649-0448", "_self")}
                >
                  <PhoneOutlined />
                  CALL {SERVICE_LINE}
                </Button>
              </div>
            ) : (
              <div className="contact-page__form">
                <Form form={form} layout="vertical" size="large" hideRequiredMark>
                  <Row gutter={[16, 0]}>
                    <Col xs={24} md={12}>
                      <Form.Item
                        label="First Name"
                        name="first_name"
                        rules={[
                          {
                            required: true,
                            message: "First name is required"
                          }
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                      <Form.Item
                        label="Last Name"
                        name="last_name"
                        rules={[
                          {
                            required: true,
                            message: "Last name is required"
                          }
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col xs={24}>
                      <Form.Item
                        label="Email Address"
                        name="email"
                        rules={[
                          { required: true, message: "Email is required" },
                          { type: "email", message: "Please enter a valid email" }
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                      <Form.Item
                        label="Phone Number"
                        name="phone_number"
                        rules={[
                          {
                            required: true,
                            message: "Phone number is required"
                          }
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                      <Form.Item
                        label="Zip Code"
                        name="zip"
                        rules={[
                          {
                            required: true,
                            message: "Zip is required"
                          }
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col xs={24}>
                      <Form.Item label="Message" name="message">
                        <Input.TextArea placeholder="Your message here..." rows={2} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={8} style={{ marginLeft: "auto" }}>
                      <Form.Item className="pt-4">
                        <Button
                          onClick={sendInquiry}
                          className="button is-primary"
                          type="primary"
                          size="large"
                          disabled={loading}
                          loading={loading}
                          block
                        >
                          SEND INQUIRY
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </div>
            )}
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default ContactPage;
